var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full dci-web-app",
      class: _vm.mode === "modal-only" && !_vm.isModal ? "hidden" : null
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll-lock",
              rawName: "v-scroll-lock",
              value: _vm.isModal,
              expression: "isModal"
            }
          ],
          class: _vm.isModal
            ? "fixed inset-0 h-stretch w-screen z-100"
            : "relative aspect"
        },
        [
          !_vm.isMobile
            ? _c("progress-bar", {
                attrs: { "progress-total": _vm.progressTotal }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "absolute inset-0 w-full h-full dci-web-app-bg-layer"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "absolute inset-0 flex items-center justify-center w-full h-full overflow-y-scroll",
              attrs: { id: "quizInnerContent" }
            },
            [
              _vm.page === 1
                ? _c(
                    "div",
                    {
                      staticClass: "absolute inset-0 dci-web-app-start-bg-image"
                    },
                    [
                      _c("img", {
                        staticClass: "block object-cover w-full h-full",
                        attrs: {
                          src: _vm.options.image.url,
                          alt: _vm.options.image.alt
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.page === 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "relative z-10 flex flex-col items-center px-8 text-center"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "inline-block leading-none dci-web-app-label"
                        },
                        [_vm._v(_vm._s(_vm.options.label.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        { staticClass: "leading-none dci-web-app-title" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.options.title.text) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "w-10/12 mx-auto leading-tight md:w-2/3 dci-web-app-intro-copy"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.options.intro.text) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "flex items-center justify-center py-4 pl-10 pr-5 dci-web-app-launch-btn",
                          on: {
                            click: function($event) {
                              return _vm.begin()
                            }
                          }
                        },
                        [
                          _vm._v("\n          Start Quiz\n          "),
                          _c("svg-sprite", {
                            staticClass:
                              "self-center w-3 h-3 fill-current ml-7",
                            attrs: { href: "icon-arrow-right" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.page > 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "relative flex flex-col w-full h-full pt-5 overflow-hidden md:pb-12 lg:max-w-7xl lg:py-8 xl:pt-0 xl:overflow-visible dci-web-app-content-container"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col w-11/12 px-2 mx-auto md:px-4 lg:mb-4 lg:px-2 lg:flex-row"
                        },
                        [
                          !_vm.options.label.hidden || !_vm.options.title.hidden
                            ? _c(
                                "div",
                                { staticClass: "flex flex-col lg:w-1/3" },
                                [
                                  !_vm.options.label.hidden
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "inline-block leading-none dci-web-app-modal-label"
                                        },
                                        [_vm._v(_vm._s(_vm.options.label.text))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.options.title.hidden
                                    ? _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "mb-6 mr-20 leading-none md:mb-10 lg:mb-0 lg:mr-0 dci-web-app-modal-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.options.title.text) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isLoading ? _c("loading") : _vm._e(),
                      _vm._v(" "),
                      _c("categories", { ref: "categories" }),
                      _vm._v(" "),
                      !_vm.isLoading
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "relative z-10 w-11/12 mx-auto mt-auto mb-2 lg:w-full lg:px-4 lg:mb-0"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-between px-2 text-center md:justify-start md:px-4 lg:px-2"
                                },
                                [
                                  _vm.currentCategory < _vm.categoryCount
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full mb-5 md:w-auto md:mb-0 md:order-last dci-web-app-next-btn"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "flex items-center justify-center w-full py-6 md:w-auto md:px-16",
                                              on: {
                                                click: function($event) {
                                                  return _vm.next()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Next\n                "
                                              ),
                                              _c("svg-sprite", {
                                                staticClass:
                                                  "w-3 h-3 ml-2 fill-current",
                                                attrs: {
                                                  href: "icon-arrow-right"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentCategory > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center md:w-auto md:order-first md:mr-auto dci-web-app-back-btn",
                                          class: _vm.btnWidth
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "flex items-center self-center justify-center",
                                              on: {
                                                click: function($event) {
                                                  return _vm.prev()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Back\n                "
                                              ),
                                              _c("svg-sprite", {
                                                staticClass:
                                                  "order-first w-3 h-3 mr-2 transform rotate-180 fill-current",
                                                attrs: {
                                                  href: "icon-arrow-right"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentCategory !== _vm.categoryCount
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center md:w-auto md:order-3 md:ml-auto md:mr-4 dci-web-app-skip-btn",
                                          class: _vm.btnWidth
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "self-center",
                                              on: {
                                                click: function($event) {
                                                  return _vm.next()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Skip Question\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentCategory === _vm.categoryCount
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-first w-full mb-5 md:w-auto md:mb-0 md:ml-auto md:order-last dci-web-app-results-btn"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "w-full py-6 md:w-auto md:px-16",
                                              attrs: {
                                                disabled: _vm.isLoading,
                                                type: "button"
                                              },
                                              on: { click: _vm.onSubmit }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Results\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "absolute inset-0 block w-full h-full overflow-hidden pointer-events-none dci-web-app-modal-bg-image"
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 39.973 46"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M39.195 45.93l-9.083-5.244a.518.518 0 01-.259-.449V8.827l-4.334 2.5v25.772a.519.519 0 01-.777.45l-9.083-5.207a.519.519 0 01-.261-.45l.006-14.732-12.77 7.38a.519.519 0 01-.519 0L.259 23.469a.519.519 0 010-.9l17.262-9.976a.519.519 0 01.778.449l-.007 17.472 4.333 2.484V9.955a.519.519 0 01.259-.449l9.083-5.244a.519.519 0 01.778.449v34.154l4.334 2.5V1.592a.519.519 0 01.259-.449L39.194.071a.519.519 0 01.778.449v44.961a.519.519 0 01-.777.449z",
                                  fill: "#000"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile || _vm.isModal
                ? _c(
                    "button",
                    {
                      staticClass:
                        "absolute top-0 right-0 z-10 flex items-center justify-center mt-5 mr-4 md:mr-6 lg:mt-8 dci-web-app-expand-btn",
                      class: _vm.isModal
                        ? "text-white bg-transparent"
                        : "bg-white",
                      on: {
                        click: function($event) {
                          _vm.isMobile
                            ? _vm.reset()
                            : (_vm.isModal = !_vm.isModal)
                        }
                      }
                    },
                    [
                      !_vm.isModal
                        ? _c("svg-sprite", {
                            staticClass: "w-6 h-6 fill-current md:w-8 md:h-8",
                            attrs: { href: "icon-expand" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isModal && !_vm.isMobile
                        ? _c("svg-sprite", {
                            staticClass: "w-5 h-5 fill-current md:w-6 md:h-6",
                            attrs: { href: "icon-close" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isModal && _vm.isMobile
                        ? _c("svg-sprite", {
                            staticClass: "w-5 h-5 fill-current md:w-6 md:h-6",
                            attrs: { href: "icon-close" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isModal
                ? _c(
                    "span",
                    { staticClass: "absolute z-10 dci-web-app-logo" },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-8 h-8 md:w-12 md:h-12",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 39.973 46"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M39.195 45.93l-9.083-5.244a.518.518 0 01-.259-.449V8.827l-4.334 2.5v25.772a.519.519 0 01-.777.45l-9.083-5.207a.519.519 0 01-.261-.45l.006-14.732-12.77 7.38a.519.519 0 01-.519 0L.259 23.469a.519.519 0 010-.9l17.262-9.976a.519.519 0 01.778.449l-.007 17.472 4.333 2.484V9.955a.519.519 0 01.259-.449l9.083-5.244a.519.519 0 01.778.449v34.154l4.334 2.5V1.592a.519.519 0 01.259-.449L39.194.071a.519.519 0 01.778.449v44.961a.519.519 0 01-.777.449z",
                              fill: "#fff"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }