<template>
  <div class="flex flex-col w-full h-full">
    <!-- Start Loading Spinner -->
    <loading v-if="isLoading"></loading>
    <!-- End Loading Spinner -->

    <!-- Form Wrapper -->
    <div
      v-if="category && !isLoading"
      class="relative z-10 flex flex-col justify-center w-full h-full text-center"
    >
      <p
        class="w-11/12 mx-auto leading-none dci-web-app-category-description"
        v-html="category.description"
      ></p>
      <form
        :action="siteURL + '/quiz-results/'"
        method="post"
        class="w-full"
        ref="form"
      >
        <ul v-if="category.terms" :class="layoutClass">
          <li
            v-for="term in category.terms"
            :key="term.term_id"
            class="px-2 mb-4"
          >
            <input
              type="checkbox"
              :id="'term_' + term.term_id"
              :value="term.term_id"
              v-model="checkedTerms"
              class="hidden dci-web-app-category-checkbox"
            />

            <label
              :for="'term_' + term.term_id"
              class="relative flex items-center cursor-pointer dci-web-app-category-label"
            >
              <span
                v-html="term.name"
                class="inline-block px-2 leading-none term-name"
              ></span>
              <span class="items-center justify-center bg-white checkmark">
                <svg-sprite
                  href="checkmark"
                  class="w-4 h-4 fill-current"
                ></svg-sprite>
              </span>
            </label>
          </li>
        </ul>

        <input
          type="hidden"
          name="selectedTerms"
          :value="JSON.stringify(selectedCategories)"
        />
      </form>
    </div>
    <!-- Close Form Wrapper -->
  </div>
</template>

<script>
import SvgSprite from './SvgSprite'
import Loading from './Loading'

export default {
  name: 'Categories',
  components: {
    SvgSprite,
    Loading
  },
  computed: {
    layoutClass () {
      if (this.category.terms.length > 6) {
        return 'dci-web-app-category-list'
      } else {
        return 'dci-web-app-category-tiles'
      }
    },
    siteURL () {
      return dciNeighborhoodQuizJS.siteURL
    },
    category () {
      return this.categories[this.currentCategory]
    },
    categories () {
      return this.$store.state.categories
    },
    currentCategory () {
      return this.$store.state.currentCategory
    },
    isLoading () {
      return this.$store.state.isLoading
    },
    checkedTerms: {
      get () {
        return this.$store.state.checkedTerms
      },
      set (value) {
        this.$store.commit('setCheckedTerms', value)
      }
    },
    selectedCategories () {
      return this.$store.state.selectedCategories
    }
  }
}
</script>
