var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col w-full h-full" },
    [
      _vm.isLoading ? _c("loading") : _vm._e(),
      _vm._v(" "),
      _vm.category && !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "relative z-10 flex flex-col justify-center w-full h-full text-center"
            },
            [
              _c("p", {
                staticClass:
                  "w-11/12 mx-auto leading-none dci-web-app-category-description",
                domProps: { innerHTML: _vm._s(_vm.category.description) }
              }),
              _vm._v(" "),
              _c(
                "form",
                {
                  ref: "form",
                  staticClass: "w-full",
                  attrs: {
                    action: _vm.siteURL + "/quiz-results/",
                    method: "post"
                  }
                },
                [
                  _vm.category.terms
                    ? _c(
                        "ul",
                        { class: _vm.layoutClass },
                        _vm._l(_vm.category.terms, function(term) {
                          return _c(
                            "li",
                            { key: term.term_id, staticClass: "px-2 mb-4" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedTerms,
                                    expression: "checkedTerms"
                                  }
                                ],
                                staticClass:
                                  "hidden dci-web-app-category-checkbox",
                                attrs: {
                                  type: "checkbox",
                                  id: "term_" + term.term_id
                                },
                                domProps: {
                                  value: term.term_id,
                                  checked: Array.isArray(_vm.checkedTerms)
                                    ? _vm._i(_vm.checkedTerms, term.term_id) >
                                      -1
                                    : _vm.checkedTerms
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.checkedTerms,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = term.term_id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkedTerms = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkedTerms = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkedTerms = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "relative flex items-center cursor-pointer dci-web-app-category-label",
                                  attrs: { for: "term_" + term.term_id }
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "inline-block px-2 leading-none term-name",
                                    domProps: { innerHTML: _vm._s(term.name) }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "items-center justify-center bg-white checkmark"
                                    },
                                    [
                                      _c("svg-sprite", {
                                        staticClass: "w-4 h-4 fill-current",
                                        attrs: { href: "checkmark" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "selectedTerms" },
                    domProps: { value: JSON.stringify(_vm.selectedCategories) }
                  })
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }