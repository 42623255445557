<template>
  <!-- DCI Quiz -->
  <div
    class="w-full dci-web-app"
    :class="mode === 'modal-only' && !isModal ? 'hidden' : null"
  >
    <div
      :class="
        isModal ? 'fixed inset-0 h-stretch w-screen z-100' : 'relative aspect'
      "
      v-scroll-lock="isModal"
    >
      <progress-bar
        :progress-total="progressTotal"
        v-if="!isMobile"
      ></progress-bar>

      <!-- Opacity Layer -->
      <div class="absolute inset-0 w-full h-full dci-web-app-bg-layer"></div>

      <!-- Inner Content -->
      <div
        id="quizInnerContent"
        class="absolute inset-0 flex items-center justify-center w-full h-full overflow-y-scroll"
      >
        <!-- BG Image -->
        <div
          v-if="page === 1"
          class="absolute inset-0 dci-web-app-start-bg-image"
        >
          <img
            :src="options.image.url"
            :alt="options.image.alt"
            class="block object-cover w-full h-full"
          />
        </div>

        <!-- Start Wrapper -->
        <div
          v-if="page === 1"
          class="relative z-10 flex flex-col items-center px-8 text-center"
        >
          <span class="inline-block leading-none dci-web-app-label">{{
            options.label.text
          }}</span>
          <h2 class="leading-none dci-web-app-title">
            {{ options.title.text }}
          </h2>
          <p
            class="w-10/12 mx-auto leading-tight md:w-2/3 dci-web-app-intro-copy"
          >
            {{ options.intro.text }}
          </p>
          <button
            class="flex items-center justify-center py-4 pl-10 pr-5 dci-web-app-launch-btn"
            @click="begin()"
          >
            Start Quiz
            <svg-sprite
              href="icon-arrow-right"
              class="self-center w-3 h-3 fill-current ml-7"
            ></svg-sprite>
          </button>
        </div>
        <!-- End Start Wrapper -->

        <!-- Content Container -->
        <div
          v-if="page > 1"
          class="relative flex flex-col w-full h-full pt-5 overflow-hidden md:pb-12 lg:max-w-7xl lg:py-8 xl:pt-0 xl:overflow-visible dci-web-app-content-container"
        >
          <!-- Title Header -->
          <div
            class="flex flex-col w-11/12 px-2 mx-auto md:px-4 lg:mb-4 lg:px-2 lg:flex-row"
          >
            <div
              class="flex flex-col lg:w-1/3"
              v-if="!options.label.hidden || !options.title.hidden"
            >
              <span
                class="inline-block leading-none dci-web-app-modal-label"
                v-if="!options.label.hidden"
                >{{ options.label.text }}</span
              >
              <h2
                class="mb-6 mr-20 leading-none md:mb-10 lg:mb-0 lg:mr-0 dci-web-app-modal-title"
                v-if="!options.title.hidden"
              >
                {{ options.title.text }}
              </h2>
            </div>
          </div>
          <!-- End Title Header -->

          <!-- Start Loading Spinner -->
          <loading v-if="isLoading"></loading>
          <!-- End Loading Spinner -->

          <categories ref="categories"></categories>

          <!-- Buttons -->
          <div
            v-if="!isLoading"
            class="relative z-10 w-11/12 mx-auto mt-auto mb-2 lg:w-full lg:px-4 lg:mb-0"
          >
            <div
              class="flex flex-wrap justify-between px-2 text-center md:justify-start md:px-4 lg:px-2"
            >
              <!-- Next -->
              <div
                v-if="currentCategory < categoryCount"
                class="w-full mb-5 md:w-auto md:mb-0 md:order-last dci-web-app-next-btn"
              >
                <button
                  class="flex items-center justify-center w-full py-6 md:w-auto md:px-16"
                  @click="next()"
                >
                  Next
                  <svg-sprite
                    href="icon-arrow-right"
                    class="w-3 h-3 ml-2 fill-current"
                  ></svg-sprite>
                </button>
              </div>

              <!-- Back -->
              <div
                v-if="currentCategory > 0"
                :class="btnWidth"
                class="flex justify-center md:w-auto md:order-first md:mr-auto dci-web-app-back-btn"
              >
                <button
                  class="flex items-center self-center justify-center"
                  @click="prev()"
                >
                  Back
                  <svg-sprite
                    href="icon-arrow-right"
                    class="order-first w-3 h-3 mr-2 transform rotate-180 fill-current"
                  ></svg-sprite>
                </button>
              </div>

              <!-- Skip -->
              <div
                v-if="currentCategory !== categoryCount"
                :class="btnWidth"
                class="flex justify-center md:w-auto md:order-3 md:ml-auto md:mr-4 dci-web-app-skip-btn"
              >
                <button class="self-center" @click="next()">
                  Skip Question
                </button>
              </div>

              <!-- Indicators -->
              <!-- <ul class="absolute items-center justify-center order-2 hidden w-full h-full pointer-events-none lg:flex dci-web-app-indicators">
                <li></li>
                <li></li>
                <li class="is-selected"></li>
                <li></li>
                <li></li>
                <li></li>
              </ul> -->

              <!-- Results -->
              <div
                v-if="currentCategory === categoryCount"
                class="order-first w-full mb-5 md:w-auto md:mb-0 md:ml-auto md:order-last dci-web-app-results-btn"
              >
                <button
                  :disabled="isLoading"
                  class="w-full py-6 md:w-auto md:px-16"
                  type="button"
                  @click="onSubmit"
                >
                  Results
                </button>
              </div>
            </div>
          </div>
          <!-- End Buttons -->

          <!-- Logo BG -->
          <span
            class="absolute inset-0 block w-full h-full overflow-hidden pointer-events-none dci-web-app-modal-bg-image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.973 46">
              <path
                d="M39.195 45.93l-9.083-5.244a.518.518 0 01-.259-.449V8.827l-4.334 2.5v25.772a.519.519 0 01-.777.45l-9.083-5.207a.519.519 0 01-.261-.45l.006-14.732-12.77 7.38a.519.519 0 01-.519 0L.259 23.469a.519.519 0 010-.9l17.262-9.976a.519.519 0 01.778.449l-.007 17.472 4.333 2.484V9.955a.519.519 0 01.259-.449l9.083-5.244a.519.519 0 01.778.449v34.154l4.334 2.5V1.592a.519.519 0 01.259-.449L39.194.071a.519.519 0 01.778.449v44.961a.519.519 0 01-.777.449z"
                fill="#000"
              />
            </svg>
          </span>
          <!-- Close Logo BG -->
        </div>
        <!-- Close Content Container -->

        <!-- Expand Screen Button -->
        <button
          v-if="!isMobile || isModal"
          v-on:click="isMobile ? reset() : (isModal = !isModal)"
          class="absolute top-0 right-0 z-10 flex items-center justify-center mt-5 mr-4 md:mr-6 lg:mt-8 dci-web-app-expand-btn"
          :class="isModal ? 'text-white bg-transparent' : 'bg-white'"
        >
          <!-- Expand Icon (default outside modal) -->
          <svg-sprite
            v-if="!isModal"
            href="icon-expand"
            class="w-6 h-6 fill-current md:w-8 md:h-8"
          ></svg-sprite>

          <!-- Collapse Icon (desktop modal) -->
          <svg-sprite
            v-if="isModal && !isMobile"
            href="icon-close"
            class="w-5 h-5 fill-current md:w-6 md:h-6"
          ></svg-sprite>

          <!-- Close Icon (mobile modal) -->
          <svg-sprite
            v-if="isModal && isMobile"
            href="icon-close"
            class="w-5 h-5 fill-current md:w-6 md:h-6"
          ></svg-sprite>
        </button>
        <!-- Close Expand Screen Button -->

        <!-- Logo -->
        <span v-if="isModal" class="absolute z-10 dci-web-app-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 md:w-12 md:h-12"
            viewBox="0 0 39.973 46"
          >
            <path
              d="M39.195 45.93l-9.083-5.244a.518.518 0 01-.259-.449V8.827l-4.334 2.5v25.772a.519.519 0 01-.777.45l-9.083-5.207a.519.519 0 01-.261-.45l.006-14.732-12.77 7.38a.519.519 0 01-.519 0L.259 23.469a.519.519 0 010-.9l17.262-9.976a.519.519 0 01.778.449l-.007 17.472 4.333 2.484V9.955a.519.519 0 01.259-.449l9.083-5.244a.519.519 0 01.778.449v34.154l4.334 2.5V1.592a.519.519 0 01.259-.449L39.194.071a.519.519 0 01.778.449v44.961a.519.519 0 01-.777.449z"
              fill="#fff"
            />
          </svg>
        </span>
        <!-- Close Logo -->
      </div>
      <!-- Close Inner Content -->
    </div>
    <!-- Close modal conditional wrapper -->
  </div>
  <!-- Close DCI Quiz -->
</template>

<script>
import Categories from './Categories'
import ProgressBar from './ProgressBar'
import Loading from './Loading'
import SvgSprite from './SvgSprite'
import debounce from 'lodash'

export default {
  name: 'neighborhood-quiz',
  components: {
    ProgressBar,
    Categories,
    SvgSprite,
    Loading,
    SvgSprite
  },
  props: {
    mode: {
      required: false,
      type: String,
      default: ''
    },
    progressTotal: {
      type: Number
    }
  },
  data () {
    return {
      isModal: false,
      page: 1
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    isLoading () {
      return this.$store.state.isLoading
    },
    siteURL () {
      return dciNeighborhoodQuizJS.siteURL
    },
    categoryCount () {
      return this.$store.state.categories.length - 1
    },
    currentCategory () {
      return this.$store.state.currentCategory
    },
    categories () {
      return this.$store.state.categories
    },
    options () {
      return this.$store.state.options
    },
    btnWidth () {
      return 'w-full'
    },
    checkedTerms () {
      return this.$store.state.checkedTerms
    }
  },
  watch: {},
  methods: {
    begin () {
      this.page = 2
      if (this.isMobile) {
        this.isModal = true
      }
      this.$store.commit('addProgress', 1)
    },
    reset () {
      this.page = 1
      this.isModal = false
      this.$store.commit('setProgress', 0)
    },
    next () {
      let value = this.currentCategory
      value++

      this.$store.commit('setCurrentCategory', value)
      this.$store.commit('addProgress', 1)
    },
    prev () {
      let value = this.currentCategory
      value--

      this.$store.commit('setCurrentCategory', value)
      this.$store.commit('removeProgress', 1)
    },
    handler (event) {
      // If escape key is pressed...
      if (this.isModal && event && event.keyCode == 27) {
        this.isModal = false
      }
    },
    resize () {
      var viewportWidth =
        window.innerWidth || document.documentElement.clientWidth
      if (viewportWidth < 1024) {
        this.$store.commit('setMobile', true)
      } else {
        this.$store.commit('setMobile', false)
      }
    },
    onSubmit () {
      const terms = {}

      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i]
        // Loop through the main categories from the store
        for (let j = 0; j < category.terms.length; j++) {
          // Loop through the term array in the single category
          const term = category.terms[j] // Get a term from the terms array of current category

          if (this.checkedTerms.indexOf(term.term_id) !== -1) {
            // It is there? if not returns -1
            if (typeof terms[category.name] === 'undefined') {
              terms[category.name] = []
            }

            terms[category.name].push(term.term_id)
          }
        }
      }

      // console.log(this.$refs);
      // console.log(this.$refs.categories.$refs.form);

      this.$store.commit('setSelectedCategories', terms)

      this.$nextTick(() => {
        this.$refs.categories.$refs.form.submit() // Submit the form
      })
    }
  },
  mounted () {
    window.addEventListener('resize', debounce(this.resize, 100))
    window.addEventListener('keyup', this.handler)

    this.$nextTick(() => {
      window.addEventListener('dciNeighborhoodQuizLaunch', e => {
        this.isModal = true
        this.begin()
      })
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('keyup', this.handler)
  },
  created () {
    this.resize()
    this.$store.dispatch('getCategories')
  }
}
</script>
