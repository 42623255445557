// Import Vue
import Vue from "vue";
import Vuex from "vuex";

// Store
import Store from "./store";

// Scroll lock
import VScrollLock from "v-scroll-lock";

Vue.use(VScrollLock);
Vue.use(Vuex);

import NeighborhoodQuiz from "../vue/NeighborhoodQuiz";

// Assign to variable
const store = new Vuex.Store(Store);

(function() {
  new Vue({
    store: store,
    el: "#neighborhoodquiz",
    components: {
      NeighborhoodQuiz
    }
  });

  const neighborhoodElms = document.querySelectorAll(
    "a[href='#findneighborhood-webapp']"
  );

  for (const elm of neighborhoodElms) {
    elm.addEventListener("click", e => {
      e.preventDefault();

      // Create a new event, allow bubbling, and provide any data
      const dciNeighborhoodQuizLaunch = new Event("dciNeighborhoodQuizLaunch");

      // Dispatch the event.
      window.dispatchEvent(dciNeighborhoodQuizLaunch);
    });
  }

  // WebFont.load({
  //   google: {
  //     families: ["Open Sans Condensed:300,700", "Open Sans:300,600,700"]
  //   }
  // });
})();
