<template>
  <div class="flex flex-col self-center justify-center h-full dci-web-app-loading">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="self-center w-12 h-12 mb-2"
      stroke="#fff"
      viewBox="0 0 38 38"
    >
      <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
        <circle stroke-opacity="0.5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>

    <span class="block text-base">Loading</span>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>
