<template>
  <div class="dci-web-app-progress-bar">
    <div class="dci-web-app-progress-made" :style="styles"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progressTotal: {
      type: Number
    }
  },
  computed: {
    progress() {
      return this.$store.state.progress;
    },
    styles() {
      return {
        width: 100 / (this.progressTotal / this.progress) + "%"
      };
    }
  }
};
</script>
