var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col self-center justify-center h-full dci-web-app-loading"
    },
    [
      _c(
        "svg",
        {
          staticClass: "self-center w-12 h-12 mb-2",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            stroke: "#fff",
            viewBox: "0 0 38 38"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                transform: "translate(1 1)",
                "stroke-width": "2",
                fill: "none",
                "fill-rule": "evenodd"
              }
            },
            [
              _c("circle", {
                attrs: { "stroke-opacity": "0.5", cx: "18", cy: "18", r: "18" }
              }),
              _vm._v(" "),
              _c(
                "path",
                { attrs: { d: "M36 18c0-9.94-8.06-18-18-18" } },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      type: "rotate",
                      from: "0 18 18",
                      to: "360 18 18",
                      dur: "1s",
                      repeatCount: "indefinite"
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "block text-base" }, [_vm._v("Loading")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }