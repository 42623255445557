const axios = require('axios')

export default {
  state: {
    categories: [],
    checkedTerms: [],
    selectedCategories: {},
    currentCategory: 0,
    progress: 0,
    isMobile: false,
    isLoading: false,
    options: {
      image: {
        url: '/wp-content/themes/wake/dist/img/quiz-marquee.jpg',
        alt: 'Ralegih skyline'
      },
      title: {
        text: 'Let Us Build Your Day In The Triangle',
        hidden: true
      },
      intro: {
        text:
          'Whether you prefer nature and craft beer, or art galleries and fancy dinner, the Triangle has something for everyone. Answer a few questions and let us show you a custom itinerary to spend your best day here.'
      },
      label: {
        text: 'Day In The Triangle Quiz',
        hidden: true
      }
    }
  },
  mutations: {
    setCategories (state, data) {
      state.categories = data
    },
    setCurrentCategory (state, data) {
      state.currentCategory = data
    },
    addProgress (state, data) {
      state.progress += data
    },
    setLoading (state, data) {
      state.isLoading = data
    },
    setProgress (state, data) {
      state.progress = data
    },
    removeProgress (state, data) {
      state.progress -= data
    },
    setMobile (state, data) {
      state.isMobile = data
    },
    setCheckedTerms (state, data) {
      state.checkedTerms = data
    },
    setSelectedCategories (state, data) {
      state.selectedCategories = data
    }
  },
  actions: {
    getCategories ({ commit }) {
      commit('setLoading', true)
      // Get Taxonomies attached to post type
      axios
        .get(
          dciNeighborhoodQuizJS.siteURL +
            '/wp-json/neighborhood-quiz/v1/categories'
        )
        .then(response => {
          commit('setLoading', false)
          commit('setCategories', response.data)

          return response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
